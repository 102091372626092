import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import store from '@/store/index.ts'
import { router } from './router/router';
import 'vant/lib/index.css';

import axios from "axios";

import { Icon,
         Swipe,
         SwipeItem,
         NavBar,
         Cell,
         CellGroup,
         Grid,
         GridItem,
         Row,
         Col,
         Toast,
         Form,
         Button,
         Field,
         tag,
         Search,
         AddressList,
         AddressEdit,
         Area,
         List,
         Stepper,
         Image as VanImage,
         Pagination,
         Skeleton,
         Card, 
         Tab, Tabs,
         Popup,
         Collapse, CollapseItem,
         ConfigProvider,
         Picker,
         NoticeBar  } from 'vant';

const app = createApp(App);

app.config.globalProperties.aa = 'bb'
//自定义函数
//跳转函数
app.config.globalProperties.onHandleRouter =function (val){
   this.$router.push(val);
 };
//
 app.config.globalProperties.onGoBack =function (){
   history.back();
 };

 axios.defaults.timeout = 60000;
 app.config.globalProperties.$axios = axios;

app.use(Icon)
   .use(Swipe)
   .use(SwipeItem)
   .use(NavBar)
   .use(Cell)
   .use(CellGroup)
   .use(Grid)
   .use(GridItem)
   .use(Row)
   .use(Col)
   .use(Toast)
   .use(Form)
   .use(Button)
   .use(Field)
   .use(tag)
   .use(Search)
   .use(AddressList)
   .use(AddressEdit)
   .use(Area)
   .use(List)
   .use(Stepper)
   .use(VanImage)
   .use(Pagination)
   .use(Skeleton)
   .use( Card )
   .use( Tab) .use(Tabs)
   .use( Collapse) .use(CollapseItem)
   .use(Picker)
   .use(Popup)
   .use(NoticeBar)
   .use(ConfigProvider);
app.use(store);
app.use(router);

app.mount('#app');
