import { createRouter, createWebHashHistory } from 'vue-router';
import { useUserStore } from '@/store/user.ts'
import axios from 'axios'

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
    meta: {
      title: '防伪管理系统',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/view/home.vue'),
    meta: {
      title: '防伪管理系统',
    },
  },
  //登录系统
  {
    name: 'login',
    path: '/login',
    component: () => import('@/view/login/login.vue'),
    meta: {
      title: '登录系统',
    },
  },
  //注册
  {
    name: 'register',
    path: '/register',
    component: () => import('@/view/login/register.vue'),
    meta: {
      title: '注册',
    },
  },
  //重置密码
  {
    name: 'resetpassword',
    path: '/resetpassword',
    component: () => import('@/view/login/resetpassword'),
    meta: {
      title: '重置密码',
    },
  },
  //修改密码
  {
    name: 'modifypassword',
    path: '/modifypassword',
    component: () => import('@/view/login/modifypassword'),
    meta: {
      title: '修改密码',
    },
  },

  //user info
  {
    name: 'serveuser',
    path: '/serveuser',
    component: () => import('@/view/user/serveuser'),
    meta: {
      title: '人员列表',
    },
  },
  {
    name: 'invite',
    path: '/invite',
    component: () => import('@/view/user/invite'),
    meta: {
      title: '邀请',
      authority: {
        role: 'level1'
      }
    },
  },

  {
    name: 'modifyuserinfo',
    path: '/modifyuserinfo',
    component: () => import('@/view/user/modifyuserinfo'),
    meta: {
      title: '修改用户信息',
    },
  },
  {
    name: 'manageruserinfo',
    path: '/manageruserinfo',
    component: () => import('@/view/user/manageruserinfo'),
    meta: {
      title: '管理用户',
    },
  },
  {
    name: 'userinfo',
    path: '/userinfo',
    component: () => import('@/view/user/userinfo'),
    meta: {
      title: '用户信息',
    },
  },
  {
    name: 'modifyphone',
    path: '/modifyphone',
    component: () => import('@/view/user/modifyphone'),
    meta: {
      title: '变更手机号',
    },
  },
  {
    name: 'modifyuserprimaryinfo',
    path: '/modifyuserprimaryinfo',
    component: () => import('@/view/user/modifyuserprimaryinfo'),
    meta: {
      title: '基本信息管理',
    },
  },

  
  //货物管理
  {
    name: 'goodslist',
    path: '/goodslist',
    component: () => import('@/view/goods/goodslist'),
    meta: {
      title: '货物列表',
    },
  },
  {
    name: 'stockgoodslistr',
    path: '/stockgoodslist',
    component: () => import('@/view/goods/stockgoodslist'),
    meta: {
      title: '库存列表',
    },
  },
  {
    name: 'exwarehouse',
    path: '/exwarehouse',
    component: () => import('@/view/goods/exwarehouseall'),
    meta: {
      title: '出货管理',
    },
  },
  {
    name: ' returngoods',
    path: '/returngoods',
    component: () => import('@/view/goods/returngoods'),
    meta: {
      title: '退货管理',
    },
  },
  {
    name: 'exwarehousehb95',
    path: '/exwarehousehb95',
    component: () => import('@/view/goods/exwarehousehb95'),
    meta: {
      title: '划拨管理',
    },
  },
  {
    name: 'exwarehousehb',
    path: '/exwarehousehb',
    component: () => import('@/view/goods/exwarehousehb'),
    meta: {
      title: '划拨管理',
    },
  },
  {
    name: 'exwaregoodslist',
    path: '/exwaregoodslist',
    component: () => import('@/view/goods/exwaregoodslist'),
    meta: {
      title: '出库列表',
    },
  },

  //工具
  {
    name: 'searchorderuser',
    path: '/searchorderuser',
    component: () => import('@/view/goods/searchorderuser'),
    meta: {
      title: '选择收货人',
    },
  },
  //95划拨
  {
    name: 'searchorderuserhb95',
    path: '/searchorderuserhb95',
    component: () => import('@/view/goods/searchorderuserhb95'),
    meta: {
      title: '选择收货人',
    },
  },
  //划拨选择收货人
  {
    name: 'searchorderuserhb',
    path: '/searchorderuserhb',
    component: () => import('@/view/goods/searchorderuserhb'),
    meta: {
      title: '选择收货人',
    },
  },
  //已知原密码修改密码
  {
    name: 'xgpassword',
    path: '/xgpassword',
    component: () => import('@/view/user/xgpassword'),
    meta: {
      title: '修改密码',
    },
  },
  {
    name: 'sysinfo',
    path: '/sysinfo',
    component: () => import('@/view/manager/sysinfo'),
    meta: {
      title: '系统信息',
    },
  },

  //查询商品最终用户信息，防止作假
  {
    name: 'checkproinfo',
    path: '/checkproinfo',
    component: () => import('@/view/goods/checkproinfo'),
    meta: {
      title: '商品防伪信息',
    },
  },
  //通过微信扫一扫查询
  {
    name: 'checkprobelong',
    path: '/checkprobelong/:id(\\d+)',
    component: () => import('@/view/goods/checkprobelong'),
    meta: {
      title: '商品防伪信息',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

function hasPermission(authority, permissions) {
  let required = '*'
  if (typeof authority === 'string') {
    required = authority
  } else if (typeof authority === 'object') {
    required = authority.permission
  }
  return required === '*' || (permissions && permissions.findIndex(item => item === required || item.id === required) !== -1)
}

function hasRole(authority, roles) {
  let required = undefined
  if (typeof authority === 'object') {
    required = authority.role
  }
  return authority === '*' || hasAnyRole(required, roles)
}

function hasAnyRole(required, roles) {
  if (!required) {
    return false
  } else if(Array.isArray(required)) {
    return roles.findIndex(role => {
      return required.findIndex(item => item === role || item === role.id) !== -1
    }) !== -1
  } else {
    console.log('aa'+roles+' '+required)
    return roles.findIndex(role => role === required || role.id === required) !== -1
    
  }
}

function hasAuthority2(route, permissions, roles) {
  const authorities = [...route.meta.pAuthorities, route.meta.authority]
  for (let authority of authorities) {
    if (!hasPermission(authority, permissions) && !hasRole(authority, roles)) {
      return false
    }
  }
  return true
}

function hasAuthority(route, roles) {
  const authorities = [route.meta.authority]
  console.log(authorities)
  for (let authority of authorities) {
    console.log(authority)
    if ( !hasRole(authority, roles)) {
      return false
    }
  }
  return true
}


export async function  haslastlogin(){
  const userStore = useUserStore()
  let tokens={'Authorization':userStore.userInfo.token}
  const res= await axios.get(process.env.VUE_APP_API_PORT_BASE_URL+'/user/select',{params:{},headers:tokens})
  if(res.data==""){
    return false
  }else{
    return true
  }
}

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const title = to.meta && to.meta.title;
  if (to.name=='register' || to.name=='modifypassword' || to.name=='resetpassword' || to.name=='checkproinfo' || to.name=='checkprobelong') {
    document.title = title;
    next();
  }else{
    haslastlogin().then(res=>{
    if (userStore.userInfo.name && res ) {
      document.title = title;
      next();
    }else{
      if (to.path === '/login') {
        next();
      } else {
        next('/login')
      }
    }
  })
  }
 // console.log(hasAuthority(to, [{id: 'level3', operation: ['add', 'edit', 'delete']}]))
});
/*
router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const title = to.meta && to.meta.title;
  if (to.name=='register' || to.name=='modifypassword' || to.name=='resetpassword' || to.name=='checkproinfo' || to.name=='checkprobelong' ) {
    document.title = title;
    next();
  }
    if (userStore.userInfo.name  ) {
      document.title = title;
      next();
    }else{
      if (to.path === '/login') {
        next();
      } else {
        next('/login')
      }
    }
});*/

export { router };
