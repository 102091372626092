
import { defineStore } from 'pinia'

export const useUserStore = defineStore({
  id: 'userInfo',
  state: () => {
    return {
      userInfo:{data:{}}
    }
  },
  getters: {

  },
  actions: {
    setUserInfo(data) {
      this.userInfo=data
    }
  },
  //持久化，默认 sessionStorage
  persist: {
    enabled: true,
    strategies: [
      {
        //更改KEY
        key: 'my_user',
        //更改存储为localStorage
        storage: localStorage,
        //自定义持久化变量
        paths: ['userInfo']
      }
    ]
  }
})




